import { render, staticRenderFns } from "./IncomingStockListV2.vue?vue&type=template&id=064b33a0&scoped=true&"
import script from "./IncomingStockListV2.vue?vue&type=script&lang=js&"
export * from "./IncomingStockListV2.vue?vue&type=script&lang=js&"
import style0 from "./IncomingStockListV2.vue?vue&type=style&index=0&id=064b33a0&scoped=true&lang=css&"
import style1 from "./IncomingStockListV2.vue?vue&type=style&index=1&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "064b33a0",
  null
  
)

export default component.exports
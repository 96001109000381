<template>
    <v-fade-transition>
        <div class="col-12 pa-0">
            <v-sheet class="dense-inputs">
                <v-row>
                    <v-col class="col-2 col-xl-3 d-flex flex-row align-end">
                        <div class="d-flex flex-column">
                            <span class="font-sm">{{ $t('message.warehouse') }}</span>
                            <v-select
                                :items="filteredOffices"
                                class="filter-width-100 bg-lightblue"
                                dense
                                hide-details="auto"
                                item-value="Office.code"
                                item-text="Office.code"
                                solo
                                v-model="filterValues.warehouse"
                                @change="filterSalesColleagueList"
                            />
                        </div>
                        <v-btn class="ml-3 px-2" @click="fetchReport()" :loading="loading.incomingStock">{{ $t('message.refetch') }}</v-btn>
                        <v-btn class="ml-3 px-2" @click="openTotalsDialog()">{{ $t('message.showTotals') }}</v-btn>
                    </v-col>
                    <v-col class="col-10 col-xl-9 d-flex flex-row align-end">
                        <v-spacer/>
                        <div class="ml-5 mt-3">
                            <div class="d-flex flex-row">
                                <div class="d-flex flex-column">
                                    <v-menu offset-y>
                                        <template v-slot:activator="{ on: menu, attrs }">
                                            <v-tooltip bottom>
                                                <template v-slot:activator="{ on: tooltip }">
                                                    <v-btn
                                                        color="default"
                                                        v-bind="attrs"
                                                        v-on="{ ...tooltip, ...menu }"
                                                    >
                                                        <v-icon class="far fa-file-pdf" color="red darken-1"></v-icon>
                                                    </v-btn>
                                                </template>
                                                <span>PDF</span>
                                            </v-tooltip>
                                        </template>
                                        <v-list>
                                            <v-list-item class="text-no-wrap" @click="exportList('pdf','download')">{{ $t('message.downloadPdf') }}</v-list-item>
                                            <v-list-item class="text-no-wrap" @click="openSendListDialog('pdf')">{{ $t('message.emailToSales') }}</v-list-item>
                                        </v-list>
                                    </v-menu>
                                    <span>{{ $t('message.buyer') }}</span>
                                </div>
                                <div class="d-flex flex-column">
                                    <v-menu offset-y>
                                        <template v-slot:activator="{ on: menu, attrs }">
                                            <v-tooltip bottom>
                                                <template v-slot:activator="{ on: tooltip }">
                                                    <v-btn
                                                        color="default"
                                                        class="ml-3"
                                                        v-bind="attrs"
                                                        v-on="{ ...tooltip, ...menu }"
                                                    >
                                                        <v-icon class="far fa-file-excel" color="green darken-2"></v-icon>
                                                    </v-btn>
                                                </template>
                                                <span>Excel</span>
                                            </v-tooltip>
                                        </template>
                                        <v-list>
                                            <v-list-item class="text-no-wrap" @click="exportList('excel','download')">{{ $t('message.downloadExcel') }}</v-list-item>
                                            <v-list-item class="text-no-wrap" @click="openSendListDialog('excel')">{{ $t('message.emailToSales') }}</v-list-item>
                                        </v-list>
                                    </v-menu>
                                    <span class="ml-3">{{ $t('message.internal') }}</span>
                                </div>
                            </div>
                        </div>
                    </v-col>
                </v-row>
                <v-row no-gutters>
                    <v-col class="col-12 mt-2">
                        <div class="d-flex flex-row">
                            <v-autocomplete
                                :items="filterOptions.po"
                                :placeholder="$t('message.po')"
                                class="font-sm"
                                clearable
                                dense
                                hide-details="auto"
                                multiple
                                solo
                                @change="filterList('po',$event).then(() => { calculateTotalsNew().then(() => {loading.incomingStock = false}).catch(() => {loading.incomingStock = false}) }).catch(() => {loading.incomingStock = false})"
                            />
                            <v-autocomplete
                                :items="filterOptions.supplier"
                                :placeholder="capitalize($t('message.spl'))"
                                class="font-sm ml-2"
                                clearable
                                dense
                                hide-details="auto"
                                multiple
                                solo
                                @change="filterList('supplier',$event).then(() => { calculateTotalsNew().then(() => {loading.incomingStock = false}).catch(() => {loading.incomingStock = false}) }).catch(() => {loading.incomingStock = false})"
                            />
                            <v-autocomplete
                                :items="filterOptions.product"
                                :placeholder="$t('message.product')"
                                class="font-sm ml-2"
                                clearable
                                dense
                                hide-details="auto"
                                multiple
                                solo
                                @change="filterList('product',$event).then(() => { calculateTotalsNew().then(() => {loading.incomingStock = false}).catch(() => {loading.incomingStock = false}) }).catch(() => {loading.incomingStock = false})"
                            />
                            <v-autocomplete
                                :items="filterOptions.species"
                                :placeholder="$t('message.species')"
                                class="font-sm ml-2"
                                clearable
                                dense
                                hide-details="auto"
                                multiple
                                solo
                                @change="filterList('species',$event).then(() => { calculateTotalsNew().then(() => {loading.incomingStock = false}).catch(() => {loading.incomingStock = false}) }).catch(() => {loading.incomingStock = false})"
                            />
                            <v-autocomplete
                                :items="filterOptions.certification"
                                :placeholder="$t('message.certificate')"
                                class="font-sm ml-2"
                                clearable
                                dense
                                hide-details="auto"
                                multiple
                                solo
                                @change="filterList('certification',$event).then(() => { calculateTotalsNew().then(() => {loading.incomingStock = false}).catch(() => {loading.incomingStock = false}) }).catch(() => {loading.incomingStock = false})"
                            />
                            <v-autocomplete
                                :items="filterOptions.grade"
                                :placeholder="$t('message.grade')"
                                class="font-sm ml-2"
                                clearable
                                dense
                                hide-details="auto"
                                multiple
                                solo
                                @change="filterList('grade',$event).then(() => { calculateTotalsNew().then(() => {loading.incomingStock = false}).catch(() => {loading.incomingStock = false}) }).catch(() => {loading.incomingStock = false})"
                            />
                            <v-autocomplete
                                :items="filterOptions.thickness"
                                :placeholder="$t('message.thickness')"
                                class="font-sm ml-2"
                                clearable
                                dense
                                hide-details="auto"
                                multiples
                                solo
                                @change="filterList('thickness',$event).then(() => { calculateTotalsNew().then(() => {loading.incomingStock = false}).catch(() => {loading.incomingStock = false}) }).catch(() => {loading.incomingStock = false})"
                            />
                        </div>
                    </v-col>
                </v-row>
            </v-sheet>
            <v-overlay
                :value="loading.incomingStock"
                absolute
                opacity="0.15"
            >
                <v-row>
                    <v-col class="text-center">
                        <v-progress-circular
                            color="primary"
                            indeterminate
                            size="40"
                            width="6"
                        />
                    </v-col>
                </v-row>
            </v-overlay>
            <v-data-table
                    :fixed-header="true"
                    :headers="headers"
                    :height="tableHeight"
                    :items="filteredItems"
                    :items-per-page="-1"
                    :sort-by="'Stock.etaDate'"
                    :selectable-key="'Stock.id'"
                    calculate-widths
                    class="mt-3 appic-table-light specification-table"
                    dense
                    hide-default-footer
                    item-key="Stock.id"
                    multi-sort
                    ref="incomingStockTable"
                    show-select
                    v-model="selectedStock"
            >
                <template v-slot:item.Stock.supplier="{ item }">
                    <div>{{ item.Stock.supplier }}</div>
                </template>
                <template v-slot:item.Stock.etaDate="{ item }">
                    <div>{{ formatDate(item.Stock.etaDate) }}</div>
                </template>
                <template v-slot:item.Stock.volume="{ item }">
                    <div class="text-end">{{ item.Stock.volume }}</div>
                </template>
                <template v-slot:item.Stock.uofmVolume="{ item }">
                    <div class="text-start">{{ item.Stock.uofmVolume }}</div>
                </template>
                <template v-slot:item.Stock.currencyOriginal="{ item }">
                    <span>{{ item.Stock.currencyOriginal }}</span>
                </template>
                <template v-slot:item.Stock.buyingPrice="{ item }">
                    <div class="text-end">{{ numberFormat(item.Stock.buyingPrice, '0,0.00') }}</div>
                </template>
                <template v-slot:item.Stock.currencyLocal="{ item }">
                    <span>{{ item.Stock.currencyLocal }}</span>
                </template>
                <template v-slot:item.Stock.buyingPriceLocal="{ item }">
                    <div class="text-end">{{ numberFormat(item.Stock.buyingPriceLocal, currencyFormat) }}</div>
                </template>
                <template v-slot:item.Stock.currencySelling="{ item }">
                    <div class="text-end font-weight-bold">{{ item.Stock.currencySelling }}</div>
                </template>
                <template v-slot:item.Stock.suggestedSellingPrice_1="{ item }">
                    <div class="text-end font-weight-bold">{{ numberFormat(item.Stock.suggestedSellingPrice['pm_' + parseInt(priceMargins[0].PriceMargin.margin).toString()], item.Stock.sellingPricePrecision) }}</div>
                </template>
                <template v-slot:item.Stock.suggestedSellingPrice_2="{ item }">
                    <div class="text-end font-weight-bold">{{ numberFormat(item.Stock.suggestedSellingPrice['pm_' + parseInt(priceMargins[1].PriceMargin.margin).toString()], item.Stock.sellingPricePrecision) }}</div>
                </template>
                <template v-slot:item.Stock.suggestedSellingPrice_3="{ item }">
                    <div class="text-end font-weight-bold">{{ numberFormat(item.Stock.suggestedSellingPrice['pm_' + parseInt(priceMargins[2].PriceMargin.margin).toString()], item.Stock.sellingPricePrecision) }}</div>
                </template>
            </v-data-table>
            <IncomingStockListTotals
                :dialog.sync="totalsDialog"
                :totals="totals"
                @dialog-closed="totalsDialogClosed"
            />
            <v-dialog
                persistent
                transition="dialog-bottom-transition"
                v-model="sendListDialog"
                width="400"
                content-class="min-max-width-unset"
            >
                <v-card>
                    <v-card-title class="success white--text">{{ $t('message.sendListToSalesColleagues') }}</v-card-title>
                    <v-card-text class="white">
                        <div class="mt-2 font-md">{{ $t('message.selectSalesColleagues') }}</div>
                        <v-simple-table class="appic-table-light specification-tables" dense>
                            <template v-slot:default>
                                <thead>
                                <tr>
                                    <th class="grey lighten-3 pa-0 width-1-pct"></th>
                                    <th class="grey lighten-3 pa-0 text-left">{{ $t('message.name')}}</th>
                                </tr>
                                </thead>
                                <tbody v-if="salesColleagues.length > 0">
                                <tr v-for="salesColleague in salesColleagues" v-bind:key="salesColleague.Salesperson.id">
                                    <td>
                                        <v-checkbox
                                            :value="salesColleague.Salesperson.userid"
                                            class="mt-1"
                                            dense
                                            hide-details
                                            v-model="selectedSalesColleagues"
                                        />
                                    </td>
                                    <td class="font-weight-bold pt-2 pl-0 text-left">{{ salesColleague.Salesperson.title }}</td>
                                </tr>
                                </tbody>
                                <tbody v-else>
                                <tr>
                                    <td colspan="2">{{ $t('message.noContactsFound') }}</td>
                                </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                    </v-card-text>
                    <v-card-actions class="pr-3 pt-3 white">
                        <v-spacer></v-spacer>
                        <v-btn
                            class="ml-3"
                            color="default"
                            small
                            @click="selectedSalesColleagues = []; sendListDialog = false"
                        >{{ $t('message.cancel') }}
                        </v-btn>
                        <v-btn
                            :loading="loading.send"
                            class="ml-3"
                            color="info"
                            small
                            @click="sendListToSales()"
                        >{{ $t('message.send') }}
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </div>
    </v-fade-transition>
</template>

<script>
import {api} from "Api";
import {mapActions, mapGetters} from "vuex";
import {capitalize, formatDate, numberFormat} from "Helpers/helpers";
import {mapFields} from "vuex-map-fields";

const IncomingStockListTotals = () => import("Components/Appic/StockReports/IncomingStockListTotals");

export default {
    name: "IncomingStockListV2",
    components: {IncomingStockListTotals},
    data() {
        return {
            exRateTitle: {
                MY: "MYR -> 1 USD",
                ID: "IDR -> 1 USD",
            },
            filteredItems: [],
            filterOptions: {
                buyingPrice: [],
                buyingPriceLocal: [],
                certification: [],
                containers: [],
                etaDate: [],
                etaDateWh: [],
                grade: [],
                length: [],
                po: [],
                product: [],
                species: [],
                supplier: [],
                thickness: [],
                volume: [],
                width: []
            },
            filterValues: {
                warehouse: 'MY',
                buyingPrice: [],
                buyingPriceLocal: [],
                certification: [],
                containers: [],
                etaDate: [],
                etaDateWh: [],
                grade: [],
                length: [],
                po: [],
                product: [],
                quantity: [],
                species: [],
                supplier: [],
                thickness: [],
                volume: [],
                width: []
            },
            items: [],
            loading: {
                incomingStock: false,
                send: false
            },
            priceMargins: [
                {
                    PriceMargin: {
                        id: 1,
                        title: 'Margin 0%',
                        margin: 0
                    }
                },
                {
                    PriceMargin: {
                        id: 2,
                        title: 'Margin 0%',
                        margin: 0
                    }
                },
                {
                    PriceMargin: {
                        id: 3,
                        title: 'Margin 0%',
                        margin: 0
                    }
                }
            ],
            reportType: 'pdf',
            salesColleagues: [],
            selectedSalesColleagues: [],
            selectedStock: [],
            sendListDialog: false,
            tableHeight: '500',
            totals: [],
            totalsDialog: false,
            totalBuyingCosts: {},
            totalBuyingCostsByProduct: {},
            totalQuantities: {},
            totalVolumes: {}
        }
    },
    computed: {
        ...mapGetters([
            'offices'
        ]),
        ...mapFields('runtime',{
            openedTabs: 'openedTabs',
            stockReportsCurrentWarehouse: 'stockReportsCurrentWarehouse'
        }),
        ...mapFields('stock', {
            currentExchangeRates__lastUpdate: 'currentExchangeRates.lastUpdate',
            currentExchangeRates__MY: 'currentExchangeRates.MY',
            currentExchangeRates__ID: 'currentExchangeRates.ID'
        }),
        ...mapGetters('salescolleague', {
            allSalesColleagues: 'allSalesColleagues',
            notSalesColleagues: 'notSalesColleagues'
        }),
        ...mapGetters('user',{
            User: 'User'
        }),
        ...mapGetters([
            'offices'
        ]),
        cssVars() {
            return {
                '--table-height': this.tableHeight.toString() + 'px'
            }
        },
        currencyFormat() {
            let currencyFormat = '0,0.00'
            if(this.filterValues.warehouse === 'ID') currencyFormat = '0,0'
            return currencyFormat
        },
        filteredOffices () {
            return this.offices.filter( o => [1,21].includes(o.Office.id))
        },
        headers() {
            return [
                {
                    id: 1,
                    text: this.$t('message.eta'),
                    value: 'Stock.etaDate',
                    class: 'light-green lighten-3 pa-1',
                    sortable: true,
                    searchable: true
                },
                {
                    id: 2,
                    text: this.$t('message.po'),
                    value: 'Stock.po',
                    class: 'light-green lighten-3 pa-1',
                    sortable: true,
                    searchable: true
                },
                {
                    id: 3,
                    text: this.$t('message.spl'),
                    value: 'Stock.supplier',
                    class: 'light-green lighten-3 pa-1',
                    sortable: true,
                    searchable: true
                },
                {
                    id: 4,
                    text: this.$t('message.product'),
                    value: 'Stock.product',
                    class: 'light-green lighten-3 pa-1',
                    sortable: true,
                    searchable: true
                },
                {
                    id: 5,
                    text: this.$t('message.species'),
                    value: 'Stock.species',
                    class: 'light-green lighten-3 pa-1',
                    sortable: true,
                    searchable: true
                },
                {
                    id: 6,
                    text: this.$t('message.certificate'),
                    value: 'Stock.certification',
                    class: 'light-green lighten-3 pa-1',
                    sortable: true,
                    searchable: true
                },
                {
                    id: 7,
                    text: this.$t('message.grade'),
                    value: 'Stock.grade',
                    class: 'light-green lighten-3 pa-1',
                    sortable: true,
                    searchable: true
                },
                {
                    id: 8,
                    text: 'T',
                    value: 'Stock.thickness',
                    class: 'light-green lighten-3 pa-1',
                    sortable: true,
                    searchable: true
                },
                {
                    id: 9,
                    text: 'W',
                    value: 'Stock.width',
                    class: 'light-green lighten-3 pa-1',
                    sortable: true,
                    searchable: true
                },
                {
                    id: 10,
                    text: 'L',
                    value: 'Stock.length',
                    class: 'light-green lighten-3 pa-1',
                    sortable: true,
                    searchable: true
                },
                {
                    id: 11,
                    text: this.$t('message.qty'),
                    value: 'Stock.volume',
                    class: 'light-green lighten-3 pa-1 text-end',
                    sortable: true,
                    searchable: true
                },
                {
                    id: 12,
                    text: this.$t('message.unit'),
                    value: 'Stock.uofmVolume',
                    class: 'light-green lighten-3 pa-1 width-1-pct',
                    sortable: true,
                    searchable: true
                },
                {
                    id: 13,
                    text: this.$t('message.cur'),
                    value: 'Stock.currencyOriginal',
                    class: 'light-green lighten-3 pa-1',
                    cellClass: 'min-width-40',
                    sortable: false,
                    searchable: false
                },
                {
                    id: 14,
                    text: this.$t('message.buyingPriceOriginal'),
                    value: 'Stock.buyingPrice',
                    class: 'light-green lighten-3 pa-1 text-end',
                    sortable: false,
                    searchable: false
                },
                {
                    id: 15,
                    text: 'SP: ' + this.priceMargins[0].PriceMargin.margin.toString() + '% (OC)',
                    value: 'Stock.suggestedSellingPrice_1',
                    class: 'light-green lighten-3 pa-1 text-end',
                    sortable: false,
                    searchable: false,
                    cellClass: 'amber lighten-3'
                },
                {
                    id: 16,
                    text: 'SP: ' + this.priceMargins[1].PriceMargin.margin.toString() + '% (OC)',
                    value: 'Stock.suggestedSellingPrice_2',
                    class: 'light-green lighten-3 pa-1 text-end',
                    sortable: false,
                    searchable: false,
                    cellClass: 'amber lighten-3'
                },
                {
                    id: 17,
                    text: 'SP: ' + this.priceMargins[2].PriceMargin.margin.toString() + '% (OC)',
                    value: 'Stock.suggestedSellingPrice_3',
                    class: 'light-green lighten-3 pa-1 text-end',
                    sortable: false,
                    searchable: false,
                    cellClass: 'amber lighten-3'
                }
            ]
        }
    },
    methods: {
        ...mapActions('salescolleague', {
            getAllSalesColleagues: 'getAllSalesColleagues',
            getNotSalesColleagues: 'getNotSalesColleagues'
        }),
        calculateTotals() {
            return new Promise((resolve, reject) => {
                try {
                    this.totalBuyingCosts = {}
                    this.totalBuyingCostsByProduct = {}
                    this.totalQuantities = {}
                    this.totalVolumes = {}
                    this.items.forEach(item => {
                        if( !this.totalVolumes.hasOwnProperty(item.Stock.uofmVolume) ) this.totalVolumes[item.Stock.uofmVolume] = 0
                        if( !this.totalBuyingCosts.hasOwnProperty(item.Stock.uofmVolume) ) this.totalBuyingCosts[item.Stock.uofmVolume] = 0
                        this.totalVolumes[item.Stock.uofmVolume] += parseFloat(item.Stock.volume)
                        this.totalBuyingCosts[item.Stock.uofmVolume] += parseFloat(item.Stock.buyingCost)

                        if( !this.totalBuyingCostsByProduct.hasOwnProperty(item.Stock.product) ) this.totalBuyingCostsByProduct[item.Stock.product] = 0
                        this.totalBuyingCostsByProduct[item.Stock.product] += parseFloat(item.Stock.buyingCost)
                    })
                    this.totalBuyingCostsByProduct.sort((a, b) => {
                        if(a > b) return -1
                        if(a < b) return 1
                        return 0
                    })
                    resolve('done')
                } catch(e) {
                    reject(e)
                }
            })
        },
        calculateTotalsNew() {
            return new Promise((resolve, reject) => {
                try {
                    this.totals = [...this.filteredItems.reduce((r, o) => {
                        const key = o.Stock.product + '-' + o.Stock.certification + '-' + o.Stock.species + '-' + o.Stock.uofmVolume
                        const item = r.get(key) || Object.assign({}, {
                            product: o.Stock.product,
                            certificate: o.Stock.certification,
                            species: o.Stock.species,
                            uofm: o.Stock.uofmVolume,
                            originalCurrency: o.Stock.currencyOriginal
                        }, {
                            total: 0,
                            totalCostOriginal: 0,
                        })
                        item.total += o.Stock.volume
                        item.totalCostOriginal += o.Stock.buyingPrice * o.Stock.volume
                        return r.set(key, item)
                    }, new Map).values()]
                    resolve('done')
                } catch(e) {
                    reject(e)
                }
            })
        },
        capitalize,
        clearFilterOptions() {
            return new Promise((resolve, reject) => {
                try {
                    this.headers.forEach(header => {
                        if (header.searchable) {
                            const option = header.value
                            const [a, b] = option.split('.')
                            this.filterOptions[b] = []
                        }
                    })
                    resolve('done')
                } catch(e) {
                    reject(e)
                }
            })
        },
        async exportList(reportType, method) {
            const date = new Date()
            const day = date.getDate();
            const month = date.getMonth() + 1;
            const year = date.getFullYear();
            const fullDate = year + "-" + month + "-" + day

            const reportFileName = 'Incoming_Stock_List_' + fullDate
            const url = '/reports/stocks/incoming/export'
            const headers = [];
            let reportFileExtension = '.pdf';
            let reportFileType = 'application/pdf';

            if(reportType === 'excel'){
                reportFileExtension = '.xlsx'
                reportFileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            }

            try {
                this.$toast(this.$t('message.successes.exportedListReadyMoment'), {
                    classes: ['text--white'],
                    color: 'info',
                    timeout: 0,
                    icon: 'check',
                    iconColor: 'white',
                    x: 'center',
                    y: 'top'
                })

                let data = []

                const selectedItemsForExport = this.selectedStock.length > 0 ? this.selectedStock : this.filteredItems

                selectedItemsForExport.forEach(item => {

                    const itemObj = {
                        id: item.Stock.id,
                        eta: item.Stock.etaDate,
                        po: item.Stock.po,
                        supplier: item.Stock.supplierCode,
                        product: item.Stock.product,
                        species: item.Stock.species,
                        claim: item.Stock.certification,
                        grade: item.Stock.grade,
                        thickness: item.Stock.thickness,
                        width: item.Stock.width,
                        length: item.Stock.length,
                        volume: item.Stock.volume,
                        uofmVolume: item.Stock.uofmVolume,
                        sellingCurrency: item.Stock.currencySelling,
                        buyingPrice: item.Stock.buyingPrice
                    }
                    this.priceMargins.forEach(pm => {
                        itemObj['sellingPrice' + parseInt(pm.PriceMargin.margin).toString()] = item.Stock.suggestedSellingPrice['pm_' + parseInt(pm.PriceMargin.margin).toString()]
                    })
                    data.push(itemObj)

                })

                if(method === 'download') {
                    let exportedFile = await api.post(url, {
                        data: data,
                        fields: headers,
                        type: reportType,
                        warehouse: this.filterValues.warehouse
                    }, {
                        responseType: 'blob'
                    })
                    const fileUrl = window.URL.createObjectURL(new Blob([exportedFile.data], {type: reportFileType}));
                    const link = document.createElement('a');
                    link.href = fileUrl;
                    link.target = '_blank'
                    link.setAttribute('download', reportFileName + reportFileExtension);
                    link.click();
                    link.remove();
                    window.URL.revokeObjectURL(fileUrl);
                    //close notification
                    let cmp = this.$toast.getCmp()
                    cmp.close()

                } else {
                    if(this.selectedSalesColleagues.length > 0) {
                        this.loading.send = true;
                        api
                            .post(url, {
                                contacts: this.selectedSalesColleagues,
                                data: data,
                                fields: headers,
                                send_to_sales: true,
                                type: reportType,
                                warehouse: this.filterValues.warehouse
                            })
                            .then(response => {
                                if(response.data.status === 'success') {
                                    this.$toast.success(this.$t('message.successes.priceListSent'),
                                        {
                                            classes: ['icon-float-left'],
                                            icon: 'check_circle_outline'
                                        }
                                    )
                                } else {
                                    this.$toast.error(this.$t('message.errors.priceListNotSent'),
                                        {
                                            classes: ['icon-float-left'],
                                            icon: 'error_outline'
                                        }
                                    )
                                }
                                this.loading.send = false;
                            })
                            .catch(() => {
                                this.$toast.error(this.$t('message.errors.priceListNotSent'),
                                    {
                                        classes: ['icon-float-left'],
                                        icon: 'error_outline'
                                    }
                                )
                                this.loading.send = false;
                            })
                    } else {
                        this.$toast.error(this.$t('message.errors.pleaseSelectOneContact'),
                            {
                                classes: ['icon-float-left'],
                                icon: 'error_outline'
                            }
                        )
                    }
                }
            } catch(error){
                this.$toast.error(this.$t('message.errors.couldNotExportList') + ": " + error, {
                    timeout: 7000,
                    x: 'center',
                    y: 'top'
                })
            }
        },
        fetch() {
            return new Promise((resolve, reject) => {
                try {
                    this.loadIncomingStock()
                        .then(() => {
                            this.loadFilterOptions()
                                .then(() => {
                                    resolve('done')
                                })
                                .catch((e) => {
                                    reject(e)
                                })
                        })
                        .catch((e) => {
                            reject(e)
                        })
                } catch(e) {
                    reject(e)
                }
            })
        },
        fetchReport() {
            this.loading.incomingStock = true
            this.clearFilterOptions()
                .then(() => {
                    this.fetch()
                        .then(() => {
                            this.filterList()
                                .then(() => {
                                    this.loading.incomingStock = false
                                    this.calculateTotalsNew()
                                        .then(() => {
                                            this.loading.incomingStock = false
                                        })
                                        .catch(() => {
                                            this.loading.incomingStock = false
                                        })
                                })
                                .catch(() => {
                                    this.loading.incomingStock = false
                                })
                        })
                        .catch(() => {
                            this.loading.incomingStock = false
                        })
                })
                .catch(() => {
                    this.loading.incomingStock = false
                })
        },
        filterList(column = null, value = []) {
            return new Promise((resolve, reject) => {
                try {
                    this.filterValues[column] = value
                    let filterFunctions = []
                    for(const field in this.filterValues){
                        if(field !== 'warehouse') {
                            if (this.filterValues[field] != null && this.filterValues[field].length > 0) {
                                filterFunctions.push((item) => {
                                    return this.filterValues[field].includes(item['Stock'][field])
                                })
                            }
                        }
                    }
                    if(filterFunctions.length > 0){
                        this.filteredItems = this.items.filter(item => filterFunctions.every(f => f(item)))
                    } else {
                        this.filteredItems = this.items
                    }
                    resolve('done')
                } catch (e) {
                    reject(e)
                }
            })
        },
        filterSalesColleagueList() {
            this.$nextTick(() => {
                let countryId = null;
                const warehouse = this.filterValues.warehouse;

                if(warehouse === 'MY') countryId = 1
                if(warehouse === 'ID') countryId = 4

                api
                    .get('/salescolleagues/wh-sales', {
                        params: {
                            country_id: countryId
                        }
                    })
                    .then((response) => {
                        if(response.data.status === 'success') {
                            this.salesColleagues = response.data.data
                        } else {
                            console.log('cannot load sales contact list')
                            if(countryId != null) {
                                this.salesColleagues = this.allSalesColleagues.filter(sc => {
                                    return sc.Country.id === countryId && this.notSalesColleagues.includes(sc.Salesperson.id) === false;
                                })
                            } else {
                                this.salesColleagues = this.allSalesColleagues;
                            }
                        }
                    })
                    .catch(() => {
                        console.log('cannot load sales contact list')
                        if(countryId != null) {
                            this.salesColleagues = this.allSalesColleagues.filter(sc => {
                                return sc.Country.id === countryId && this.notSalesColleagues.includes(sc.Salesperson.id) === false;
                            })
                        } else {
                            this.salesColleagues = this.allSalesColleagues;
                        }
                    })
            })
        },
        formatDate,
        getPriceMargins() {
            return new Promise((resolve, reject) => {
                api
                    .get('/warehouse-vars/price-margin')
                    .then((response) => {
                        const priceMargins = response.data.data
                        resolve(priceMargins)
                    })
                    .catch((error) => {
                        reject(error)
                    })
            })
        },
        getUpdatedExRate() {
            let warehouseCurrency = 'MYR'
            if(this.filterValues.warehouse === 'ID') warehouseCurrency = 'IDR'
            const currencies = {
                source: 'USD',
                target: warehouseCurrency
            }
            api
                .put('/reports/stocks/user-exchange-rate', {
                    currencies: currencies
                })
                .then((response) => {
                    if(response.data.status === 'success') {
                        if (this.filterValues.warehouse === 'MY') this.currentExchangeRates__MY = response.data.rate
                        if (this.filterValues.warehouse === 'ID') this.currentExchangeRates__ID = response.data.rate
                        this.currentExchangeRates__lastUpdate = response.data.date
                    } else {
                        this.$toast.error(this.$t('message.errors.exchangeRateNotUpdated'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                }
                        )
                    }
                })
                .catch(() => {
                    this.$toast.error(this.$t('message.errors.exchangeRateNotUpdated'),
                            {
                                classes: ['icon-float-left'],
                                icon: 'error_outline'
                            }
                    )
                })
        },
        getStockExRate() {
            return new Promise((resolve, reject) => {
                let toCurrency = 'MYR'
                if(this.filterValues.warehouse === 'ID') toCurrency = 'IDR'
                const conditions = [
                    {field: 'from_currency', value: 'USD'},
                    {field: 'to_currency', value: toCurrency},
                ]
                api
                    .get("/reports/stocks/user-exchange-rate", {
                        params: {
                            conditions: conditions
                        }
                    })
                    .then(response => {
                        if(response.data.status === 'success') {
                            if(this.filterValues.warehouse === 'MY') this.currentExchangeRates__MY = response.data.data[0]['exrate']
                            if(this.filterValues.warehouse === 'ID') this.currentExchangeRates__ID = response.data.data[0]['exrate']
                            this.currentExchangeRates__lastUpdate = response.data.data[0]['rate_date']
                            resolve('done')
                        } else {
                            resolve('error')
                        }
                    })
                    .catch(error => {
                        reject(error);
                    });
            })
        },
        handleResize() {
            this.tableHeight = window.innerHeight - 275;
            return true
        },
        loadFilterOptions() {
            return new Promise((resolve, reject) => {
                try {
                    this.headers.forEach(header => {
                        if (header.searchable) {
                            const option = header.value
                            const [a, b] = option.split('.')
                            let options = this.items.map(item => item[a][b])
                            if (options.length > 0) {
                                options.filter((item, index) => options.indexOf(item) === index)
                                options.filter(Boolean)
                                options.sort((a, b) => {
                                    a = a + '';
                                    b = b + '';
                                    return a.localeCompare(b);
                                })
                                this.filterOptions[b] = options
                            }
                        }
                    })
                    resolve('done')
                } catch(e) {
                    reject(e)
                }
            })
        },
        loadIncomingStock() {
            return new Promise((resolve, reject) => {
                const conditions = [
                    {
                        field: 'contracts.destinatione_id',
                        value: this.filterValues.warehouse
                    }
                ]
                const exRate = {
                    source: "USD",
                    target: this.filterValues.warehouse === 'MY' ? "MYR" : "IDR",
                    rate: this.filterValues.warehouse === 'MY' ? parseFloat(this.currentExchangeRates__MY) : parseFloat(this.currentExchangeRates__ID)
                }
                api
                    .get("/reports/stocks/incoming",{
                        params: {
                            conditions: conditions,
                            exrate: exRate
                        }
                    })
                    .then(response => {
                        this.items = response.data.data
                        resolve('done')
                    })
                    .catch(error => {
                        reject(error);
                    });
            })
        },
        numberFormat,
        openSendListDialog(reportType) {
            this.reportType = reportType
            this.sendListDialog = true;
        },
        openTotalsDialog () {
            this.totalsDialog = true
        },
        sendListToSales() {
            this.exportList(this.reportType,'email')
        },
        totalsDialogClosed () {
            this.totalsDialog = false;
        }
    },
    watch: {
        'filterValues.warehouse'(newVal) {
            this.stockReportsCurrentWarehouse = newVal
        }
    },
    created() {
        window.addEventListener('resize', this.handleResize)
    },
    mounted() {
        if (this.allSalesColleagues.length === 0) this.getAllSalesColleagues()
        if (this.notSalesColleagues.length === 0) this.getNotSalesColleagues()

        if(this.stockReportsCurrentWarehouse){
            this.filterValues.warehouse = this.stockReportsCurrentWarehouse;
        } else {
            if(this.User.country_id === 1) this.filterValues.warehouse = 'MY';
            if(this.User.country_id === 4) this.filterValues.warehouse = 'ID';
            if(this.User.country_id === 3) this.filterValues.warehouse = 'VN';
            if(this.User.country_id === 2) this.filterValues.warehouse = 'TH';
        }
        if(this.filterValues.warehouse == null) this.filterValues.warehouse = 'MY';

        this.filterSalesColleagueList();

        this.handleResize()

        this.getStockExRate()
            .then(() => {
                const dt1 = new Date()
                const dt2 = new Date(this.currentExchangeRates__lastUpdate)

                if(dt1.toDateString() !== dt2.toDateString()) {
                    this.getUpdatedExRate()
                }
            })
        this.getPriceMargins()
            .then((priceMargins) => {
                this.priceMargins = priceMargins
                this.fetchReport()
            })
            .catch((error) => {
                this.$toast.error(error,
                    {
                        classes: ['icon-float-left'],
                        icon: 'error_outline'
                    }
                )
            })
    },
    destroyed() {
        window.removeEventListener('resize', this.handleResize)
    }
}
</script>

<style scoped>
.col-1_5 {
    width: 12.5% !important;
    max-width: 12.5% !important;
}
.col-10_5 {
    width: 87.5% !important;
    max-width: 87.5% !important;
}
.filter-width-75 {
    width: 75px !important;
    max-width: 75px !important;
}
.filter-width-100 {
    width: 100px !important;
    max-width: 100px !important;
}
.bg-lightblue {
    background-color: lightblue;
}
.bg-lightorange {
    background-color: #FFCC80;
}
.v-data-table::v-deep .text-start {
    padding-left: 3px !important;
    padding-top: 3px !important;
    padding-right: 3px !important;
    vertical-align: middle !important;
}
.v-data-table::v-deep .v-data-table__wrapper {
    overflow-x: auto;
}
.v-data-table::v-deep  thead > tr > th:nth-child(1) {
    min-width: unset !important;
    background-color: #c5e1a5;
    width: 1px !important;
}
.v-data-table::v-deep .v-data-table-header th {
    white-space: nowrap;
}
.stock-filters {
    overflow-y: auto;
    height: var(--table-height)
}
</style>
<style>
.min-max-width-unset.v-dialog {
    max-width: 400px !important;
    min-width: 400px !important;
}
</style>